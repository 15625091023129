import { json, LoaderFunctionArgs } from '@remix-run/node'
import { requireNotAuthenticated } from '~/auth/session.server'
import { JovivaLogo } from '~/theme/icons'
import { defaultGrid, gridChildClasses } from '~/utils/layout'
import classNames from 'classnames'
import { ROUTES } from '~/routes'
import { ButtonLink } from '~/components/Button'
import {
  BackgroundElements,
  PageLayoutWithoutHeader,
} from '~/components/Layout'
import {
  ClearedErrorData,
  getApiErrorMessage,
  isErrorCodeKnown,
} from '~/api/apiError'
import { useLoaderData } from '@remix-run/react'
import { ActionErrorAlert } from '~/components/ActionErrorAlert'
const strings = {
  headline: 'Jetzt anmelden',
  description:
    'Melde dich einfach mit deiner Smile-ID an und werde Teil von joviva!',
  buttonLabel: 'Login mit Smile-ID',
  connectionErrorTitle: 'Es ist ein Fehler aufgetreten',
}
type LoaderReturnType = {
  connectionError: ClearedErrorData | undefined
}

export async function loader({ request }: LoaderFunctionArgs) {
  await requireNotAuthenticated(request)
  let connectionError: LoaderReturnType['connectionError'] = undefined
  const errorCode = new URL(request.url).searchParams.get('error')
  if (errorCode && isErrorCodeKnown(errorCode)) {
    connectionError = {
      code: errorCode,
      message: getApiErrorMessage(errorCode),
    }
  }

  return json<LoaderReturnType>({
    connectionError,
  })
}

export default function Login() {
  const { connectionError } = useLoaderData() as LoaderReturnType
  return (
    <PageLayoutWithoutHeader background={<BackgroundElements />}>
      <div className={'flex flex-1 items-center'}>
        <div className={classNames(defaultGrid, 'w-full')}>
          <JovivaLogo className='align-center col-span-full w-60 justify-self-center text-h1 md:w-80' />
          <h1
            className={classNames(
              gridChildClasses.narrow4,
              'text-color-heading mt-10 text-h1',
            )}
          >
            {strings.headline}
          </h1>
          <p
            className={classNames(gridChildClasses.narrow4, 'mt-4 text-body1')}
          >
            {strings.description}
          </p>
          {connectionError && (
            <div className={classNames(gridChildClasses.narrow4, 'mt-8')}>
              <ActionErrorAlert
                data={connectionError}
                title={strings.connectionErrorTitle}
              />
            </div>
          )}
          <div
            className={classNames(
              gridChildClasses.narrow4,
              'col-span-full mt-8 flex text-body1',
            )}
          >
            <ButtonLink
              className='w-full'
              to={ROUTES.API.AUTH.LOGIN.buildPath({})}
            >
              {strings.buttonLabel}
            </ButtonLink>
          </div>
        </div>
      </div>
    </PageLayoutWithoutHeader>
  )
}
